import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import "../stylesheets/form.scss";


class ContactForm extends Component {

    constructor (props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            subject: '',
            formErrors: {},
            formValid: false,
            formSent: false,
        };
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({[name]: value}, this.validateForm);
    }

    validateForm = () => {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let nameValid = this.state.nameValid;
        let messageValid = this.state.messageValid;
        let subjectValid = this.state.subjectValid;
        const { intl } = this.props;

        emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : intl.formatMessage({id: 'contact.error-email'});
        nameValid = this.state.name.length >= 2;
        fieldValidationErrors.name = nameValid ? '': intl.formatMessage({id: 'contact.error-name'});
        messageValid = this.state.message.length >= 2;
        fieldValidationErrors.message = messageValid ? '': intl.formatMessage({id: 'contact.error-message'});
        subjectValid = this.state.subject.length >= 2;
        fieldValidationErrors.subject = subjectValid ? '': intl.formatMessage({id: 'contact.error-subject'});

        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            nameValid: nameValid,
            messageValid: messageValid,
            subjectValid: subjectValid,
            formValid: emailValid && nameValid && messageValid && subjectValid
        });
    }

    handleSubmit = (e) => {
        if (!this.state.formValid) {
            this.setState({formSent: true});
            e.preventDefault();
        }
    }

    render() {
        const { intl } = this.props;
        const sendButtonText = intl.formatMessage({id: 'contact.send'});
        const namePH = intl.formatMessage({id: 'contact.name-placeholder'});
        const emailPH = intl.formatMessage({id: 'contact.email-placeholder'});
        const subjectPH = intl.formatMessage({id: 'contact.subject-placeholder'});
        const messagePH = intl.formatMessage({id: 'contact.message-placeholder'});

        return (
            <form
                className="pure-form pure-form-stacked"
                method="post"
                action="/contactSuccess"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
            >
                <input type="hidden" name="form-name" value="contact" />
                <div className="alert" style={{display:'none'}}></div>
                <div className={`pure-control-group ${this.state.formSent && !this.state.nameValid ? 'error' : '' }`}>
                    <label htmlFor="name"><FormattedMessage id="contact.name" /></label>
                    <input
                        className="pure-u-2-3"
                        id="name"
                        name="name"
                        placeholder={namePH}
                        value={this.state.name}
                        onChange={(event) => this.handleUserInput(event)}
                        style={{
                            'backgroundImage': 'url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;)',
                            'backgroundRepeat': 'no-repeat',
                            'backgroundAttachment': 'scroll',
                            'backgroundSize': '16px 18px',
                            'backgroundPosition': '98% 50%'
                        }}
                        type="text"
                    />
                    {this.state.formSent && !this.state.nameValid && (
                        <small>{this.state.formErrors.name}</small>
                    )}
                </div>
                <div className={`pure-control-group ${this.state.formSent && !this.state.emailValid ? 'error' : '' }`}>
                    <label htmlFor="email"><FormattedMessage id="contact.email" /></label>
                    <input
                        className="pure-u-2-3"
                        id="email"
                        name="email"
                        placeholder={emailPH}
                        type="text"
                        value={this.state.email}
                        onChange={(event) => this.handleUserInput(event)}
                    />
                    {this.state.formSent && !this.state.emailValid && (
                        <small>{this.state.formErrors.email}</small>
                    )}
                </div>
                <div className={`pure-control-group ${this.state.formSent && !this.state.subjectValid ? 'error' : '' }`}>
                    <label htmlFor="subject"><FormattedMessage id="contact.subject" /></label>
                    <input
                        className="pure-u-2-3"
                        id="subject"
                        name="subject"
                        placeholder={subjectPH}
                        type="text"
                        value={this.state.subject}
                        onChange={(event) => this.handleUserInput(event)}
                    />
                    {this.state.formSent && !this.state.subjectValid && (
                        <small>{this.state.formErrors.subject}</small>
                    )}
                </div>
                <div className={`pure-control-group ${this.state.formSent && !this.state.messageValid ? 'error' : '' }`}>
                    <label htmlFor="message"><FormattedMessage id="contact.message" /></label>
                    <textarea
                        className="pure-u-2-3"
                        id="message"
                        name="message"
                        placeholder={messagePH}
                        rows="7"
                        value={this.state.message}
                        onChange={(event) => this.handleUserInput(event)}
                    />
                    {this.state.formSent && !this.state.messageValid && (
                        <small>{this.state.formErrors.message}</small>
                    )}
                </div>
                <div className="pure-control-group pbm clearfix form-footer">
                    <ul className="inline-list pan l-left">
                        <li className="item">
                            <button
                                aria-label={sendButtonText}
                                className="pure-button button-xlarge pure-button-primary"
                                data-form-button="primary"
                                data-role="button"
                                type="submit"
                            >{sendButtonText}</button>
                        </li>
                    </ul>
                </div>
            </form>
        );
    }
}

ContactForm.propTypes = {
  intl: intlShape.isRequired
}

export default injectIntl(ContactForm);
