import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import withLayout from "../layout";
import SEO from "../components/seo";
import ContactForm from "../components/ContactForm";

import { SEO_KEYWORDS } from "../constants";

import "./contact.scss";

const ContactPage = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "contact.title" });

    return (
        <div>
            <SEO
                title={titleText}
                keywords={SEO_KEYWORDS}
                lang={props.pageContext.locale}
            />
            <main
                className="contact"
                role="main"
                itemScope
                itemType="http://schema.org/ContactPage"
            >
                <div className="container">
                    <div className="pure-g-r">
                        <section className="pure-u-1-2">
                            <header role="banner">
                                <h1
                                    className="h2 contact-title"
                                    itemProp="name"
                                >
                                    <FormattedMessage id="contact.cta" />
                                </h1>
                                <div className="contact-intro">
                                    <div className="portrait">
                                        <GatsbyImage
                                            image={
                                                props.data.fileName
                                                    .childImageSharp
                                                    .gatsbyImageData
                                            }
                                            alt="Marcos' Picture"
                                        />
                                    </div>
                                    <p
                                        className="f-copy"
                                        itemProp="description about"
                                    >
                                        <FormattedMessage id="contact.intro-1" />
                                    </p>
                                    <p className="f-copy">
                                        <FormattedMessage id="contact.intro-2" />
                                    </p>
                                    <p
                                        className="f-copy"
                                        itemProp="alternativeHeadline"
                                    >
                                        <FormattedMessage id="contact.intro-3" />
                                    </p>
                                </div>
                            </header>
                        </section>
                        <div className="pure-u-1-2">
                            <div className="contact-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

const customProps = {
    localeKey: "contact",
};

export default withLayout(customProps)(injectIntl(ContactPage));

export const pageQuery = graphql`
    {
        fileName: file(
            relativePath: { eq: "Headshot-Marcos-Iglesias-1200x1200.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(width: 332, height: 332, layout: CONSTRAINED)
            }
        }
    }
`;
